import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "footer-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Author = _resolveComponent("Author")!
  const _component_Copyright = _resolveComponent("Copyright")!
  const _component_SocialSharing = _resolveComponent("SocialSharing")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("div", {
      class: "footer-logo",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cubejs && _ctx.cubejs(...args)))
    }, [
      _createElementVNode("img", {
        src: _ctx.getFooterLogoImage,
        alt: "Footer Logo Image"
      }, null, 8, _hoisted_1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Author),
      _createVNode(_component_Copyright, {
        currentYear: _ctx.currentYear,
        progYear: _ctx.progYear
      }, null, 8, ["currentYear", "progYear"])
    ]),
    _createVNode(_component_SocialSharing)
  ]))
}